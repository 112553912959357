<template>
    <div>
        <v-overlay :value="overlay" absolute style="z-index:999999999">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-toolbar flat>
            <v-icon left>mdi-handshake</v-icon>
            <v-toolbar-title>OPORTUNIDADES</v-toolbar-title>
            <v-divider class="mx-4" vertical></v-divider>
            <!-- <v-btn 
                v-if="1 == 2" 
                color="blue" 
                small 
                dark
            >
                <v-icon>mdi-plus</v-icon> Crear
            </v-btn> -->
            
            <v-btn 
                color="blue" 
                small 
                dark 
                to="oportunidad_kb" 
                target="_blank"
            >
                <v-icon>mdi-view-column</v-icon>Tablero
            </v-btn>
            <v-autocomplete
                clearable
                v-model="servicioCrmId"
                label="Tipo de servicio"
                @change="cargarListaOportunidades"
                :items="serviciosCrm"
                item-text="nombre"
                item-value="servicio_crm_id"
                hide-details
                class="ml-6"
            />
        </v-toolbar>
        <!-- <v-toolbar flat>
        <v-col>
            <v-text-field
            clearable
            label="Número"
            v-model="busCodigo"
            @keyup.enter="cargarListaOportunidades"
            ></v-text-field>
        </v-col>
        <v-col>
            <v-text-field
            clearable
            label="Contacto"
            v-model="busContacto"
            @keyup.enter="cargarListaOportunidades"
            ></v-text-field>
        </v-col>

        <v-col>
            <v-select
            clearable
            v-model="busPipeline"
            label="Pipeline"
            @change="cargarListaOportunidades"
            :items="pipelines"
            item-text="pipeline_name"
            item-value="pipeline_id"
            >
            </v-select>
        </v-col>
        <v-col>
            <v-select
            clearable
            v-model="busEstado"
            label="Estado"
            @change="cargarListaOportunidades"
            :items="estadosPipeline"
            item-text="description"
            item-value="opportunity_stage_id"
            >
            </v-select>
        </v-col>
        <v-col>
            <v-text-field
            clearable
            label="Asignado a"
            v-model="busAsignado"
            ></v-text-field>
        </v-col>
        </v-toolbar> -->
        <v-data-table
            :headers="camposOp"
            :items-per-page="itemsPerPage"
            :items="oportunidades"
            class="elevation-1"
            small
            hide-default-footer
            :options.sync="pagination"
        >
            <template v-slot:body.prepend>
                <tr>
                <td width="20%">
                    <v-col cols="12">
                    <v-text-field
                        clearable
                        label="Número"
                        v-model="busCodigo"
                        @keyup.enter="cargarListaOportunidades"
                        @click:clear="limpiarCodigo"
                    ></v-text-field>
                    </v-col>
                </td>

                <td>
                    <v-text-field
                        clearable
                        label="Contacto"
                        v-model="busContacto"
                        @keyup.enter="cargarListaOportunidades"
                        @click:clear="limpiarContacto"
                        ></v-text-field>
                </td>
                <td width="15%">
                    <v-select
                        clearable
                        v-model="busPipeline"
                        label="Pipeline"
                        @change="cargarListaOportunidades"
                        :items="pipelines"
                        item-text="pipeline_name"
                        item-value="pipeline_id"
                    />
                </td>
                <td width="15%">
                    <v-select
                    clearable
                    v-model="busEstado"
                    label="Estado"
                    @change="cargarListaOportunidades"
                    :items="estadosPipeline"
                    item-text="description"
                    item-value="opportunity_stage_id"
                    >
                    </v-select>
                </td>
                <td></td>
                <td>
                    <v-col cols="12" md="12">
                    <v-menu>
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            class="pt-5"
                            v-model="busCreatedDateRange"
                            label="Fechas"
                            persistent-hint
                            readonly
                            append-icon="mdi-calendar-multiple"
                            v-bind="attrs"
                            v-on="on"
                            clearable
                            @click:clear="limpiarFechas"
                        />
                        </template>
                        <v-date-picker
                        range
                        v-model="fechas"
                        no-title
                        @change="cargarListaOportunidades"
                        />
                    </v-menu>
                    </v-col>
                </td>
                </tr>
            </template>
            <template v-slot:item.sales_opportunity_id="{ item }">
                <!-- <v-btn
                text
                small
                @click="abrirOportunidadDialog(item.sales_opportunity_id)"
                >
                {{ item.sales_opportunity_id }}
                </v-btn> -->

                <v-btn
                text
                small
                title="abrir oportunidad"
                :to="`/oportunidad_form/${item.sales_opportunity_id}`"
                ><v-icon small left color="green">mdi-eye</v-icon>
                {{ item.sales_opportunity_id }}
                </v-btn>

                <v-btn
                text
                small
                :to="`/crm_cotizacion?sop_id=${item.sales_opportunity_id}&quote_id=${item.quote_id}`"
                title="abrir cotización"
                ><v-icon color="blue" small>mdi-receipt-text-arrow-right</v-icon>
                {{ item.quote_id }}
                </v-btn>
            </template>

            <template v-slot:footer>
                <v-pagination
                class="mt-10"
                v-model="currentPage"
                :length="pageCount"
                @input="handlePageChange"
                total-visible="10"
                ></v-pagination>
            </template>
        </v-data-table>
        <!-- DIALOGO OPORTUNIDAD-->
        <v-dialog v-model="dialogoOportunidad" fullscreen>
            <v-card>
                <v-toolbar dark color="blue" height="40" flat>
                <v-icon left>mdi-handshake</v-icon>
                Oportunidad #
                {{ datosOportunidad.sales_opportunity_id }} ::
                {{ datosOportunidad.opportunity_name }}
                <v-spacer></v-spacer>
                <v-btn small text @click="dialogoOportunidad = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                </v-toolbar>
                <v-toolbar flat>
                <v-toolbar-title
                    >{{ datosOportunidad.pipeline }} ::{{
                    datosOportunidad.op_estado
                    }}</v-toolbar-title
                >
                <v-divider class="px-1 mx-4" vertical></v-divider>
                <v-btn color="green" dark small @click="guardarOportunidad"
                    ><v-icon>mdi-content-save</v-icon>Guardar</v-btn
                >
                </v-toolbar>
                <v-row class="mt-1 mx-2">
                <v-col cols="3">
                    <v-text-field
                    readonly
                    label="Monto estimado"
                    v-model="datosOportunidad.estimated_amount"
                    ></v-text-field>
                </v-col>
                <v-col cols="3">
                    <v-text-field
                    readonly
                    label="Fecha de cierre estimado"
                    v-model="datosOportunidad.estimated_close_date"
                    ></v-text-field>
                </v-col>
                <v-col cols="2">
                    <v-text-field
                    readonly
                    label="Cliente ID"
                    v-model="datosOportunidad.party_id"
                    ></v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field
                    readonly
                    label="Cliente"
                    v-model="datosOportunidad.cliente_nombre"
                    ></v-text-field>
                </v-col>
                </v-row>
                <v-row class="mt-1 mx-2">
                <v-col>
                    <v-text-field
                    readonly
                    label="Estado"
                    v-model="datosOportunidad.op_estado"
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                    readonly
                    label="Nombre"
                    v-model="datosOportunidad.opportunity_name"
                    ></v-text-field>
                </v-col>
                </v-row>

                <v-row class="mt-1 mx-2">
                <v-col cols="6">
                    <v-textarea
                    label="Descripcion"
                    v-model="datosOportunidad.description"
                    rows="3"
                    ></v-textarea>
                </v-col>
                <v-col cols="6">
                    <!-- <v-text-field
                    label="Cotizacion"
                    v-model="datosCotizacion.quote_id"
                    ></v-text-field> -->
                    <v-btn
                    text
                    title="Cotización"
                    target="_blank"
                    :to="
                        `/crm_cotizacion?accion=crear_op&sop_id=${datosOportunidad.sales_opportunity_id}`
                    "
                    >
                    <v-icon color="info">mdi-eye</v-icon>
                    {{ datosCotizacion.quote_id }}
                    </v-btn>
                    <v-toolbar color="green" dark class="mt-4" height="25" flat>
                    <v-toolbar-title>Cotizaciones</v-toolbar-title>
                    </v-toolbar>
                    <v-data-table
                    :items-per-page="itemsPerPage"
                    class="elevation-1"
                    small
                    hide-default-footer
                    :items="listaCotizaciones"
                    :headers="headHistorialCot"
                    >
                    <template v-slot:item.quote_id="{ item }">
                        {{ item.quote_id }}
                    </template>
                    </v-data-table>

                    <v-toolbar flat color="green" class="mt-4" height="24" dark
                    ><v-toolbar-title>Items</v-toolbar-title>
                    </v-toolbar>
                    <v-data-table
                    :headers="headersItems"
                    :items-per-page="itemsPerPage"
                    :items="productosOp"
                    class="elevation-1"
                    small
                    hide-default-footer
                    >
                    </v-data-table>

                    <v-toolbar color="green" dark class="mt-4" height="25" flat>
                    <v-toolbar-title>Adjuntos</v-toolbar-title>
                    </v-toolbar>
                    <v-data-table
                    :items-per-page="itemsPerPage"
                    class="elevation-1"
                    small
                    hide-default-footer
                    :items="listaAdjuntos"
                    :headers="headAdjuntos"
                    >
                    <template v-slot:item.content_id="{ item }">
                        {{ item.content_id }}
                    </template>
                    </v-data-table>
                    <!-- <v-file-input
                    show-size
                    label="Adjuntar archivo"
                    v-model="archivoActual"
                    @change="adjuntarArchivo"
                    ></v-file-input> -->
                </v-col>
                </v-row>

                <!-- <v-card>
                <v-row class="mt-1 mx-2">
                    <v-col cols="6">
                    <v-toolbar flat color="green" height="24" dark
                        ><v-toolbar-title>Items</v-toolbar-title>
                    </v-toolbar>
                    <v-data-table
                        :headers="headersItems"
                        :items-per-page="itemsPerPage"
                        :items="productosOp"
                        class="elevation-1"
                        small
                        hide-default-footer
                    >
                    </v-data-table>
                    </v-col>
                </v-row>
                </v-card> -->
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "ListaOportunidades",
  data: () => ({
    show: false,
    pagination: {},
    requiredRule: [(v) => !!v || "El campo es requerido"],
    options: {},
    setfilters: [],
    selected: [],
    users: [],
    serviciosCrm:[],
    totalItems: 1,
    currentPage: 1,
    itemsPerPage: 50,
    pageCount: 1,
    servicioCrmId:"",
    overlay: false,
    required: [(v) => !!v || "El campo es requerido"],
    headers: [
      {
        text: "Número",
        align: "start",
        value: "sales_opportunity_id",
      },
      { text: "Pipeline", align: "start", value: "pipeline_name" },
      { text: "Cod.Contacto", align: "start", value: "party_id_cliente" },
      { text: "Contacto", align: "start", value: "cliente_nombre" },
      { text: "Estado", align: "start", value: "sopp_estado" },
      { text: "Monto", align: "start", value: "estimated_amount" },
      {
        text: "Fecha estimada de cierre",
        align: "start",
        value: "estimated_close_date",
      },
      { text: "Asignado a", align: "start", value: "created" },
    ],
    oportunidades: [],
    dialogoOportunidad: false,
    datosOportunidad: {
      party_id: "",
      monto: 0,
      fecha_estimada_cierre: "",
      estado_id: "",
      estado: "",
      opportunity_name: "",
      cliente_nombres: "",
      description: "",
      next_step: "",
    },
    datosCotizacion: {},
    productosOp: [],
    headersItems: [
      { text: "ID", align: "start", value: "id" },
      { text: "Producto", align: "start", value: "product_name" },
      { text: "Cantidad", align: "start", value: "cantidad" },
      { text: "Valor unitario", align: "start", value: "precio" },
      { text: "Descuento", align: "start", value: "valor_descuento" },
      { text: "%IVA", align: "start", value: "pct_imp" },
      { text: "Total", align: "start", value: "total" },
    ],
    headHistorialCot: [
      { text: "Numero", align: "start", value: "quote_id" },
      { text: "Fecha", align: "start", value: "issue_date" },
      { text: "Estado", align: "start", value: "estado_cot" },
      { text: "Total", align: "start", value: "total" },
    ],
    listaCotizaciones: [],
    archivoActual: "",
    listaAdjuntos: [],
    headAdjuntos: [
      { text: "Codigo", value: "contenido" },
      { text: "Descripcion", value: "nombre" },
      { text: "Opciones", value: "opcion" },
    ],
    camposOp: [],
    pipelines: [],
    estados: [],
    busCodigo: "",
    busContacto: "",
    busPipeline: "",
    busEstado: "",
    busAsignado: "",
    busCreatedDate: "",
    //busCreatedDateRange: "",
    busEstCloseDate: "",
    busMonto: "",
    //fechas: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
    fechas: [],
  }),
  computed: {
    ...mapState("master", ["loadingBtn", "companies"]),
    ...mapState("crm", ["menu", "loadingBtn", "paramAlertQuestion"]),
    ...mapState("master", ["loadingTable", "tenantId", "user"]),
    estadosPipeline() {
      let ep = [];
      for (let i = 0; i < this.estados.length; i++) {
        if (this.estados[i].pipeline_id == this.busPipeline) {
          ep.push(this.estados[i]);
        }
      }
      return ep;
    },
    busCreatedDateRange: {
      get() {
        return this.fechas.join(" ~ ");
      },
      set() {
        this.fechas = [];
        return "";
      },
    },
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setLoadingTable",
      "setTitleToolbar",
      "setTenantId",
    ]),
    ...mapActions("master", ["requestApi", "alertNotification"]),
    ...mapActions("access", []),
    limpiarCodigo() {
      this.busCodigo = "";
      this.cargarListaOportunidades();
    },
    limpiarContacto() {
      this.busContacto = "";
      this.cargarListaOportunidades();
    },
    limpiarFechas() {
      this.fechas = [];
      this.cargarListaOportunidades();
    },
    cargarListaOportunidades() {

        this.setLoadingTable(true)
        this.overlay = true

        this.setUrl("oportunidad")
        const { sortBy, descending, page, rowsPerPage } = this.pagination;
        this.requestApi({
            method: "GET",
            data: {
                view_type: "lista",
                page: this.currentPage,
                page_count: this.pageCount,
                page_size: this.itemsPerPage,
                sales_opportunity_id: this.busCodigo,
                pipeline_id: this.busPipeline,
                opportunity_stage_id: this.busEstado,
                contacto: this.busContacto,
                servicio_crm_id: this.servicioCrmId,
                estimated_close_date: this.busEstCloseDate,
                created_stamp: this.fechas,
                sort_by: this.pagination.sortBy[0],
                sort_desc: this.pagination.sortDesc[0],
            }
        })
        .then((res) => {
            //console.log(res.data);
            this.oportunidades = res.data._embedded.oportunidad;
            this.pageCount = res.data.page_count;
            //this.currentPage = res.data.page;
            this.totalItems = res.data.total_items;
            this.overlay = false;
        })
        .catch(() => {
            this.setLoadingTable(false);
            this.overlay = false;
        });
    },
    abrirOportunidadDialog(opId) {
      this.dialogoOportunidad = true;
      this.cargarDatosOportunidad(opId);
    },

    cargarDatosOportunidad(id) {
      this.setLoadingTable(true);
      this.overlay = true;
      this.setUrl("oportunidad/" + id);
      this.requestApi({
        method: "GET",
        data: {},
      })
        .then((res) => {
          //console.log(res.data.detail);
          this.productosOp = res.data.detail.items_op;
          this.datosOportunidad = res.data.detail.oportunidad;
          this.datosCotizacion = res.data.detail.cotizacion;
          this.listaCotizaciones = res.data.detail.cotizaciones;
          this.listaAdjuntos = res.data.detail.adjuntos;
          this.overlay = false;
        })
        .then(() => {
          this.setLoadingTable(false);
          this.overlay = false;
        });
    },
    guardarOportunidad() {
      this.setLoadingTable(true);
      this.setUrl("oportunidad");
      this.requestApi({
        method: "POST",
        data: {
          accion: "modificarOportunidad",
          datosOportunidad: this.datosOportunidad,
        },
      })
        .then((res) => {
          //console.log(res.data.detail);
          this.alertNotification({
            param: {
              html: res.data.detail.msg,
            },
          });
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    handlePageChange() {
      this.cargarListaOportunidades();
    },
    adjuntarArchivo() {},
    cargarCampos() {
      this.setLoadingTable(true);
      this.setUrl("oportunidad");
      this.requestApi({
        method: "GET",
        data: {
          view_type: "camposReporte",
        },
      })
        .then((res) => {
          //console.log(res.data.detail);
          const  { pipelines, estados, campos, serviciosCrm } = res.data.detail
          this.pipelines = pipelines;
          this.estados = estados;
          this.camposOp = campos;
          this.serviciosCrm = serviciosCrm
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },
  },
  watch: {
    pagination: {
      handler() {
        this.cargarListaOportunidades();
      },
    },
  },
  mounted() {
    this.cargarListaOportunidades();
    this.cargarCampos();
    this.setTitleToolbar("OPORTUNIDADES");
  },
};
</script>

<style></style>
